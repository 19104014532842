<template>
  <form novalidate @submit.prevent="handleSubmit">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'FormComponent',
  props: {},
  data() {
    return {
      inputTypes: ['InputComponent', 'PasswordInputComponent', 'TelInputComponent', 'CheckboxComponent', 'SelectComponent', 'TextAreaComponent'],
    };
  },
  computed: {
    isValid() {
      return Object.values(this.inputs).every((input) => input);
    },
  },
  mounted() {
  },
  methods: {
    getDescendants(component) {
      let descendants = [];

      component.$children.forEach((child) => {
        descendants.push(child);
        descendants = descendants.concat(this.getDescendants(child));
      });
      return descendants;
    },
    submit() {
      this.handleSubmit();
    },
    handleSubmit() {
      const allDescendants = this.getDescendants(this);

      const inputs = allDescendants.filter(
        (child) => this.inputTypes.includes(child.$options.name),
      );
      inputs.forEach((input) => {
        input.checkValidation();
      });

      if (inputs.every((input) => input.isValid)) {
        this.$emit('submit');
      }
    },
  },
};
</script>
